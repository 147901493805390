.common {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.common:hover .link:after {
    animation: underline 0.1s linear;
    animation-fill-mode: forwards;
}

@keyframes underline {
    0% {
        height: 3px;
    }
    20% {
        height: 3.6px;
    }
    40% {
        height: 4.2px;
    }
    60% {
        height: 4.8px;
    }
    80% {
        height: 5.4px;
    }
    100% {
        height: 6px;
    }
}

.common:hover .icon {
    animation: arrow 0.1s linear;
    animation-fill-mode: forwards;
}

.common:hover .iconSmall {
    animation: arrowSmall 0.1s linear;
    animation-fill-mode: forwards;
}

@keyframes arrow {
    0% {
        margin-left: 15px;
    }
    100% {
        margin-left: 25px;
    }
}

@keyframes arrowSmall {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: 8px;
    }
}

.link {
    position: relative;
    padding: 3px 0;
    text-decoration: none;
    color: #000;
}

.link:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    display: block;
    background: #ffe482;
    width: 100%;
    height: 3px;
}

.white {
    color: #fff;
    fill: #fff;
    stroke: #fff;
}

.icon {
    margin-left: 15px;
}

.iconSmall {
    margin-left: 0;
}