.popupWrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: popupOpen 200ms linear;
}

@keyframes popupOpen {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.popupClose {
    display: none;
}

.popupOverlay {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup {
    position: absolute;
    z-index: 1010;
    display: flex;
    max-width: 640px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    background: #f5f6f7;
    color: #000;
    text-align: left;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.popupContentText {
    padding: 36px 20px 24px 20px;
}

.popupContainer {
    padding: 20px;
}

.popupSubtitle {
    margin-top: 8px;
    margin-bottom: 20px;
}

.popupBtnClose {
    position: absolute;
    top: -16px;
    right: -16px;
    z-index: 15;
    cursor: pointer;
    width: 56px;
    height: 56px;
    border: none;
    border-radius: 120px;
    background: rgba(255, 210, 46, 0.6) url(../../icons/cross.svg) no-repeat center;
}

.popupBtnClose:focus {
    outline: none;
}

.popupLinkWrap {
    color: #333;
    text-decoration: none;
    margin-top: 32px;
}

.popupLink {
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;

    /* underline */
    padding-bottom: 2px;
    border: none;
    background-color: #f5f6f7;
    border-bottom: 2px solid #ffd22e;
}

.popupLink:focus {
    outline: none;
}

.arrow {
    margin-left: 11px;
}
@media screen and (max-width: 768px) {
    .popup {
        padding: 20px 16px 24px 16px;
        border-radius: 0;
    }
    .popupSubtitle {
        margin-bottom: 22px;
    }
    .popupBtnClose {
        top: 12px;
        right: 12px;
        width: 32px;
        height: 32px;
        background: rgba(255, 210, 46, 0.6) url(../../icons/SmallCloseIcon.svg) no-repeat
            center;
    }
    .arrow {
        margin-left: 0;
    }
}

@media screen and (max-width: 320px) {
    .popup {
        max-width: 288px;
    }
}
