$maxWidth: 1360;

@mixin htmlFont($desktopSize, $mobSize) {
    $addSize: $desktopSize - $mobSize;
    $maxWidth: $maxWidth - 320;
    @media screen and (max-width: 1360px) {
      $fontSize: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth}));
      font-size: $fontSize;
      line-height: calc(#{$fontSize} + 8px);
    }
    @media screen and (min-width: 1361px) {
      $fontSize: calc((#{$desktopSize + px} + #{$mobSize + px}) * 0.7) ;
      font-size: $fontSize;
      line-height: calc(#{$fontSize} + 8px);
    }

}
.common-style {
    margin: 0;
    font-style: normal;
    font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
}

p {
    font-family: 'Roboto', sans-serif;
}

.h1 {
    @include htmlFont(56, 32);
    font-weight: 900;
}

.h2 {
    @include htmlFont(48, 24);
    font-weight: 800 !important;
}

.h3 {
    @include htmlFont(40, 24);
}

.h4 {
    @include htmlFont(32, 20);
}

.h5 {
    @include htmlFont(24, 16);
}

.h6 {
    @include htmlFont(20, 16);
}

.p1 {
    @include htmlFont(20, 14);
    font-weight: normal;
}

.p2 {
    @include htmlFont(16, 12);
    font-weight: 500;
}
.p2Header {
    @include htmlFont(16, 12);
    font-weight: normal;
}

.p2button {
    font-size: 16px;
    line-height: 44px;
    font-weight: 500;
}

.p3 {
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
}

.link {
    @include htmlFont(32, 16);
    font-weight: normal;
}

.reviewTitle {
    @include htmlFont(32, 16);
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    line-height: 44px;
}

.sliderDecorText {
    @include htmlFont(32, 16);
    font-family: 'Roboto', sans-serif;
}

.tiltCardHeader {
    @include htmlFont(48, 23);
}

.bold {
    font-weight: bold;
}
.medium {
    font-weight: 500;
}
.white {
    color: #fff;
}

.header {
    font-size: 48px;
    line-height: 68px;
    font-weight: 300;
}

.reviewHeader {
    @include htmlFont(48, 24);
    font-family: 'Gilroy', sans-serif;
    font-weight: 800 !important;
    line-height: 52px;
}

.topHeader {
    @include htmlFont(48, 32);
    font-family: 'Gilroy', sans-serif;
    font-weight: 300;
    line-height: 68px;
}

.bannerLessonTitle,
.bannerLessonSubtitle {
    font-family: Roboto, sans-serif;
}

.popupTitle {
    @include htmlFont(32, 24);
}

.moduleCard {
    @include htmlFont(16, 16);
}
.blockCard {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 40px;
  @include htmlFont(32, 16);
}

.bannerLessonTitle {
  @include htmlFont(32, 24);
  margin-bottom: 10px;
}

.bannerLessonSubtitle {
  @include htmlFont(16, 12);
  white-space: pre-line;
}

.textCardHeader {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 800;
  @include htmlFont(48, 15);
}

.textCardParagraph {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  @include htmlFont(16, 12);
}

@media screen and (max-width: 480px) {
  .topHeader {
    line-height: 42px;
  }
}

//@media screen and (max-width: 768px) {
//  .bannerLessonSubtitle {
//    margin: 0 auto 25px auto;
//  }
//
//}
@media screen and (max-width: 320px) {
    .bannerLessonTitle {
      margin: 24px 0 12px 16px;
        padding-right: 40px;
    }
}
