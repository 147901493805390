.back {
    position: absolute;
    top: 0.4em;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 70%;
    background-color: #ffe482;
}
.smallBack {
    position: absolute;
    top: 0.4em;
    left: 0;
    z-index: 3;
    width: 48px;
    height: 12px;
    background-color: #ffe482;
}
.textWithBack {
    position: relative;
    z-index: 1000;
}

.wrapText {
    position: relative;
    z-index: 100;
    display: inline-block;
}

.preformattedText {
    position: relative;
    z-index: 1001;
    display: inline;
    margin: 0;
    font-family: inherit;
}

