.feedbackInputWrap {
    position: relative;
}

.feedbackInputWrap:not(:last-of-type) {
    margin-bottom: 16px;
}

input:focus ~ .feedbackPlaceholder,
input:not(:focus):valid ~ .feedbackPlaceholder {
    top: 5px;
    left: 20px;
    font-size: 11px;
    opacity: 1;
    color: rgba(0, 0, 0, 0.6);
}

.feedbackInputText {
    top: 127px;
    left: 32px;
    font-size: 16px;
    width: 100%;
    height: 50px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 20px;
    padding-top: 15px;
    border: 2px solid #ffd22e;
}

.feedbackPlaceholder {
    position: absolute;
    top: 18px;
    left: 20px;
    pointer-events: none;
    font-family: 'Gilroy', sans-serif;
    transition: 0.2s ease all;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 700;
    font-size: 16px;
}

.feedbackInputText:focus {
    font-weight: 700;
}

.popupBtnText {
    font-style: normal;
    font-weight: bold;
    font-family: Roboto, sans-serif;
}

.popupBtnWrap {
    color: #333;
    background-color: #f5f6f7;
    text-decoration: none;
    margin-top: 32px;
    border: none;
}

.popupBtn {
    font-size: 16px;
    cursor: pointer;

    /* underline */
    padding-bottom: 2px;
    border: none;
    background-color: #f5f6f7;
    border-bottom: 2px solid #ffd22e;
}

.popupBtnWrap:focus {
    outline: none;
}

.arrow {
    margin-left: 11px;
}

.inputError {
    border: 2px solid #ef2020;
}
