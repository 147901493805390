.gifWrap {
    position: absolute;
    left: 100px;
    width: 280px;
    height: 240px;
}

.gif {
    position: absolute;
    z-index: 1;
    width: 220px;
    height: 220px;
    margin-top: 15px;
    background-size: cover;
}

.backTime {
    position: absolute;
    left: -20px;
    width: 160px;
    height: 160px;
    background-repeat: no-repeat;
}

.backWork {
    position: absolute;
    top: 80px;
    left: -30px;
    width: 160px;
    height: 160px;
    background-repeat: no-repeat;
}

.backMoney {
    position: absolute;
    left: 100px;
    width: 160px;
    height: 160px;
    background-repeat: no-repeat;
}
