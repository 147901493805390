.MainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.SectionContainer {
    display: flex;
    width: 100%;
    max-width: 1360px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0 9.5%;
    box-sizing: border-box;
    overflow-x: hidden;
}

@media (min-width: 1360px) {
    .SectionContainer {
        padding: 0 128px;
    }
}

@media screen and (max-width: 1100px) {
    .SectionContainer {
        width: 100%;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }
}