.wrap {
    width: 160px;
    height: 160px;
}

.image {
    width: inherit;
    height: 160px;
}

@media (max-width: 320px) {
    .wrap {
        width: 101px;
        height: 101px;
    }
}
