.BlockStudent {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    text-align: start;
    margin-bottom: 75px;
}

.titleWrap {
    margin-bottom: 16px;
}

.titleRectangle {
    background-color: rgba(255, 210, 46, 0.6);
}

.content {
    max-width: 1300px;
    width: 100%;
    margin: 30px 0 29px 0;
}

.firstRow {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.blockContentFirst {
    width: 180px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.blockContentFirst:not(:last-child) {
    margin-right: auto;
}

.tinkoff {
    background-image: url(../../icons/Firms/tinkoff.svg);
    height: 25px;
    width: 182px;
    background-size: cover;
}

.purrweb {
    background-image: url(../../icons/Firms/purrweb.svg);
    width: 164px;
    height: 30px;
}

.mts {
    background-image: url(../../icons/Firms/mts.svg);
    width: 125px;
    height: 30px;
}

.sberbank {
    background-image: url(../../icons/Firms/sberbank.svg);
    width: 168px;
    height: 32px;
}

.myWebS {
    background-image: url(../../icons/Firms/myWebS.svg);
    width: 117px;
}

.secondRow {
    display: flex;
    align-items: center;
}

.blockContentSecond {
    width: 200px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.blockContentSecond:not(:last-child) {
    margin-right: auto;
}

.epam {
    background-image: url(../../icons/Firms/epam.svg);
    height: 30px;
    width: 90px;
}

.codezavod {
    background-image: url(../../icons/Firms/codezavod.svg);
    width: 182px;
    height: 32px;
}

.t-g {
    background-image: url(../../icons/Firms/t-g.svg);
    width: 200px;
    height: 32px;
}

.sibdev {
    background-image: url(../../icons/Firms/sibdev.svg);
    width: 160px;
    height: 28px;
    background-size: cover;
}

.g {
    background-image: url(../../icons/Firms/g.svg);
    height: 48px;
    width: 37px;
}

.contur {
    background-image: url(../../icons/Firms/contur.svg);
    width: 135px;
    height: 40px;
}

.common {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.btnLink {
    position: relative;
    padding: 3px;
    text-decoration: none;
    color: #000;
    border: none;
    background-color: #fff;
    cursor: pointer;
}

.btnLink:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    display: block;
    background: #ffe482;
    width: 100%;
    height: 3px;
}

.common:hover .btnLink:after {
    animation: underline 0.1s linear;
    animation-fill-mode: forwards;
}

@keyframes underline {
    0% {
        height: 3px;
    }
    20% {
        height: 3.6px;
    }
    40% {
        height: 4.2px;
    }
    60% {
        height: 4.8px;
    }
    80% {
        height: 5.4px;
    }
    100% {
        height: 6px;
    }
}

.common:hover .icon {
    animation: arrow 0.1s linear;
    animation-fill-mode: forwards;
}

.common:hover .iconSmall {
    animation: arrowSmall 0.1s linear;
    animation-fill-mode: forwards;
}

@keyframes arrow {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: 10px;
    }
}

@keyframes arrowSmall {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: 8px;
    }
}

.btnLink:focus {
    outline: none;
}

.white {
    color: #fff;
    fill: #fff;
    stroke: #fff;
}

@media (max-width: 1100px) {
    .BlockStudent {
        padding: 0 5%;
    }
}

@media screen and (max-width: 768px) {
    .titleWrap,
    .simpleText,
    .common {
        padding-left: 5%;
    }

    .content {
        display: flex;
        width: 100%;
        height: auto;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        align-items: center;
        flex-direction: column;
        margin-top: 26px;
        margin-bottom: 32px;
    }
    .scrollMargin {
        margin: 0 20px;
    }
    .blockContentFirst:first-child {
        margin-left: 0;
    }
    .blockContentSecond:first-child {
        margin-left: 0;
    }
    .tinkoff {
        height: 12px;
        width: 94px;
    }
    .purrweb {
        width: 82px;
        height: 15px;
    }
    .mts {
        width: 62px;
        height: 16px;
    }
    .sberbank {
        width: 84px;
        height: 16px;
    }
    .myWebS {
        width: 59px;
        height: 16px;
    }
    .epam {
        width: 45px;
        height: 16px;
    }
    .codezavod {
        width: 92px;
        height: 16px;
    }
    .t-g {
        width: 100px;
        height: 16px;
    }
    .sibdev {
        width: 80px;
        height: 14px;
    }
    .g {
        height: 24px;
        width: 19px;
    }
    .contur {
        width: 68px;
        height: 20px;
    }
    .BlockStudent {
        margin-bottom: 52px;
        padding: 0;
    }
}

@media (max-width: 520px) {
    .content {
        align-items: flex-start;
    }
}

@media screen and (max-width: 320px) {
    .btnLink {
        padding-left: 5%;
    }
    .content {
        padding-left: 16px;
        margin-bottom: 0;
        align-items: flex-start;
    }
    .simpleText {
        width: 265px;
    }
}
