.bigWhiteYellow {
    background: url(../../icons/WebHeroLogo/BigWhiteYellow.png) no-repeat;
    width: 260px;
    height: 40px;
    background-size: 100%;
}

.bigBlackYellow {
    background: url(../../icons/WebHeroLogo/BigBlackYellow.png) no-repeat;
    width: 260px;
    height: 40px;
}

.bigWhiteBlack {
    background: url(../../icons/WebHeroLogo/BigWhiteBlack.png) no-repeat;
    width: 260px;
    height: 40px;
}

.circlePurple {
    background: url(../../icons/WebHeroLogo/CirclePurple.png) no-repeat;
    width: 52px;
    height: 52px;
}

.squarePurple {
    background: url(../../icons/WebHeroLogo/SquarePurple.png) no-repeat;
    width: 52px;
    height: 52px;
}
.topHeaderSmall {
    background: url(../../icons/WebHeroLogo/topHeaderSmall.png) no-repeat;
    width: 130px;
    height: 20px;
}
