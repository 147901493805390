.HeaderContainer {
    display: flex;
    width: 100%;
    max-width: 1100px;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}
.header {
    display: flex;
    justify-content: center;
    width: 100%;
    background: url('header-back.webp') center center / cover no-repeat;
    margin-bottom: 50px;
    padding: 32px 0 72px;
    box-sizing: border-box;
}
.header_wrap {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1100px;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}

.logo_lk {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 152px;
}

.titleWrap {
    display: flex;
    margin-bottom: 13px;
}

.info {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
}

.yellow {
    color: #ffd22e;
}

.text_wrap {
    width: 100%;
    height: auto;
    margin-bottom: 28px;
}

.link {
    margin: 0 0 116px 0;
}

.gif {
    position: absolute;
    top: 400px;
    left: 900px;
    z-index: 5;
}

.buttonWrap {
    margin: 0 30px 0;
}

@media (min-width: 1361px) {
    .titleWrap {
        display: inline-block;
    }
}

@media screen and (max-width: 1150px) {
    .header {
        padding: 32px 16px 72px;
    }

    .titleWrap {
        display: inline-block;
    }
}

@media screen and (max-width: 768px) {
    .logo_lk {
        align-items: center;
        margin-bottom: 25px;

        /* margin: 33px 0 50px 0; */
    }
    .header_wrap {
        justify-content: flex-start;

        /* margin: 0 16px; */
    }
    .header {
        padding-left: 16px;
        padding-right: 16px;

        /* height: 647px; */
    }
}

@media screen and (max-width: 600px) {
    .buttonWrap {
        margin: 0;
        width: 40px;
        height: 40px;
    }

    .text_wrap {
        margin-bottom: 35px;
    }
    .logo_lk {
        margin-bottom: 25px;
    }
    .link {
        margin-bottom: 50px;
    }
    .btn_text {
        display: none;
    }
    .header {
        padding: 9px 16px 80px;
        margin-bottom: 32px;
    }

    .titleWrap {
        margin: 0;
    }
}
