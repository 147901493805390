.LoaderIcon {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #330099;
}
.LoaderSVG {
    display: flex;
    margin: calc(50vh - 104px) auto;
}
.eb9ia5xhzdxn2_tr {
    display: flex;
    align-self: center;
    animation: eb9ia5xhzdxn2_tr__tr 1s linear infinite forwards;
}
//.eb9ia5xhzdxn4 {
//    animation: eb9ia5xhzdxn4 1s ease-out;
//}
@keyframes eb9ia5xhzdxn2_tr__tr {
    0% {
        transform: translate(104px, 104px) rotate(0deg);
    }
    100% {
        transform: translate(104px, 104px) rotate(360deg);
    }
}
//@keyframes eb9ia5xhzdxn4 {
//    0% {
//        transform: scaleY(0.1);
//    }
//    50% {
//        transform: scaleY(0.5);
//    }
//    100% {
//        transform: scaleY(1);
//    }
//}
