.button {
    display: flex;
    width: 200px;
    height: 44px;
    border-radius: 120px;
    margin: 0;
    border: none;
    cursor: pointer;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.12);
    color: #fff;
    padding: 0;
}
.small-button {
    display: flex;
    width: 44px;
    height: 44px;
    border-radius: 120px;
    margin: 0;
    border: none;
    cursor: pointer;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.12);
    color: #fff;
    padding: 0;
}
.white {
    fill: #fff;
    stroke: #fff;
}

.icon {
    margin-right: 10px;
}

.yellow-button {
    background: #ffd22e;
    color: #000;
}

.yellow-button:hover {
    background: #ffde66;
}

.buttonTitle {
    display: inline-block;
}

@media screen and (max-width: 768px) {
    .form {
        width: 100%;
    }

    .yellow-button {
        width: 100%;
    }
    .small-button {
        width: 40px;
        height: 40px;
    }
    .small-button .icon {
        width: 16px;
        margin-right: 0;
    }

    .buttonTitle {
        display: none;
    }
    .showTitle {
        display: inline;
    }
    .footer-button {
        height: 52px;
    }
}
